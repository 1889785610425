@import "variables.scss";
@import "typography.scss";
@import "form.scss";

button {
  mat-icon + span {
    vertical-align: top;
    line-height: 24px;
  }
}

.ant-btn-sm {
  mat-icon,
  .mat-icon,
  .material-symbols-outlined {
    font-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
  }
}

/* Base Table Styling */
table {
  width: 100%; /* Full width for the table */
  border-collapse: collapse; /* Merge borders for a cleaner look */
  font-family: "Arial", sans-serif; /* Consistent font */
  font-size: 14px; /* Font size for readability */
  background-color: #fff; /* Background color for the table */
  table-layout: auto; /* Allows table to adjust to content */
  margin-bottom: 1rem; /* Margin below the table */
}

/* Table Header Styling */
table thead th {
  background-color: #f5f5f5; /* Light grey background for headers */
  color: #333; /* Darker text color for headers */
  text-align: left; /* Align text to the left */
  padding: 0.75rem; /* Padding for table header cells */
  border-bottom: 2px solid #e0e0e0; /* Bottom border for headers */
  border-right: 1px solid #e0e0e0; /* Vertical border between header cells */
  font-weight: 600 !important; /* Bold font for headers */
  white-space: nowrap; /* Prevent wrapping for header text */
}

table thead th:last-child {
  border-right: none; /* Remove the right border for the last header cell */
}

/* Table Data Cell Styling */
table tbody td {
  padding: 0.75rem; /* Padding for table data cells */
  border-bottom: 1px solid #e0e0e0; /* Bottom border for rows */
  border-right: 1px solid #e0e0e0; /* Vertical border between cells */
  color: #555; /* Slightly lighter text color for data cells */
  word-wrap: break-word; /* Allow text to break within words */
  white-space: normal; /* Allow text to wrap to the next line */
  overflow-wrap: anywhere; /* Break long words */
}

table tbody td:last-child {
  border-right: none; /* Remove the right border for the last data cell */
}

/* Alternating Row Background Color */
table tbody tr:nth-child(even) {
  background-color: #fafafa; /* Lighter grey for even rows */
}

/* Hover Effect for Rows */
table tbody tr:hover {
  background-color: #f1f1f1; /* Slightly darker grey on hover */
}

/* Responsive Table for Small Screens */
@media (max-width: 768px) {
  table {
    font-size: 12px; /* Smaller font size on mobile */
  }

  table thead th,
  table tbody td {
    padding: 0.5rem; /* Reduced padding on mobile */
  }
}

/* Apply styles to the main table wrapper */
.ant-table-wrapper {
  max-width: 100%; /* Prevent the table from overflowing */
  box-sizing: border-box; /* Ensure padding and borders are included in width */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

/* Base Table Styling */
.ant-table {
  width: 100%; /* Full width for the table */
  border-collapse: collapse; /* Merge borders for a cleaner look */
  font-family: "Arial", sans-serif; /* Consistent font */
  font-size: 14px; /* Font size for readability */
  background-color: #fff; /* Background color for the table */
  table-layout: auto; /* Allows table to adjust to content */
}

/* Table Header Styling */
.ant-table-thead th {
  background-color: #f5f5f5; /* Light grey ba ckground for headers */
  color: #333; /* Darker text color for headers */
  text-align: left; /* Align text to the left */
  padding: 0.75rem; /* Padding for table header cells */
  border-bottom: 2px solid #e0e0e0; /* Bottom border for headers */
  border-right: 1px solid #e0e0e0; /* Vertical border between header cells */
  font-weight: 600 !important; /* Bold font for headers */
  white-space: nowrap; /* Prevent wrapping for header text */
}

.ant-table-thead th:last-child {
  border-right: none; /* Remove the right border for the last header cell */
}

/* Table Data Cell Styling */
.ant-table-tbody td {
  padding: 0.75rem; /* Padding for table data cells */
  border-bottom: 1px solid #e0e0e0; /* Bottom border for rows */
  border-right: 1px solid #e0e0e0; /* Vertical border between cells */
  color: #555; /* Slightly lighter text color for data cells */
  word-wrap: break-word; /* Allow text to break within words */
  white-space: normal; /* Allow text to wrap to the next line */
  overflow-wrap: anywhere; /* Break long words */
}

.ant-table-tbody td:last-child {
  border-right: none; /* Remove the right border for the last data cell */
}

/* Alternating Row Background Color */
.ant-table-tbody tr:nth-child(even) {
  background-color: #fafafa; /* Lighter grey for even rows */
}

/* Hover Effect for Rows */
.ant-table-tbody tr:hover {
  background-color: #f1f1f1; /* Slightly darker grey on hover */
}

/* Responsive Table for Small Screens */
@media (max-width: 768px) {
  .ant-table {
    font-size: 12px; /* Smaller font size on mobile */
  }

  .ant-table-thead th,
  .ant-table-tbody td {
    padding: 0.5rem; /* Reduced padding on mobile */
  }
}
