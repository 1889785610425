/* Base Font Settings */
body {
  font-family: "Arial", sans-serif; /* Base font family */
  font-size: 14px; /* Base font size */
  line-height: 1.6; /* Comfortable line height */
  color: #333; /* Base text color */
  margin: 0;
  padding: 0;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Arial", sans-serif; /* Use a consistent font for headings */
  font-weight: 700; /* Bold headings */
  margin: 0 0 1rem 0; /* Bottom margin for spacing */
  line-height: 1.2; /* Tighter line height for headings */
  color: #222; /* Darker color for headings */
}

h1 {
  font-size: 2.5rem; /* Largest heading */
  margin: 1.2rem 0 1.5rem; /* More space below */
}

h2 {
  font-size: 2rem; /* Second largest */
  margin: 1.25rem 0;
}

h3 {
  font-size: 1.75rem; /* Medium heading */
  margin: 1rem 0;
  font-weight: 600; /* Slightly lighter weight */
}

h4 {
  font-size: 1.5rem; /* Smaller heading */
  margin-bottom: 0.75rem;
  font-weight: 500; /* Further lighter weight */
}

h5 {
  font-size: 1.25rem; /* Minor heading */
  margin-bottom: 0.5rem;
  font-weight: 400; /* Regular weight */
  color: #444; /* Lighter color */
}

h6 {
  font-size: 1rem; /* Least prominent heading */
  margin-bottom: 0.25rem;
  font-weight: 400; /* Regular weight */
  color: #555; /* Even lighter color */
}

/* Paragraphs */
p {
  margin: 1rem 0;
  line-height: 1.7; /* Comfortable line height for reading */
  padding: 0; /* No padding */
  font-size: 1rem; /* Base font size, adjust if necessary */
  color: #333; /* Darker color for readability */
}

/* Links */
a {
  color: #007bff; /* Primary link color */
  text-decoration: none; /* No underline by default */
  transition: color 0.3s ease; /* Smooth transition */
}

a:hover,
a:focus {
  text-decoration: underline; /* Underline on hover/focus */
  color: #0056b3; /* Slightly darker color on hover */
}

/* Lists */
ul,
ol {
  margin: 0 0 1rem 1.5rem; /* Space below lists and indent */
  padding: 0;
  list-style-position: outside; /* List bullets outside */
  font-size: 1rem;
}

li {
  margin-bottom: 0.5rem; /* Space between list items */
}

/* Blockquotes */
blockquote {
  margin: 0 0 1rem 0; /* Space below blockquotes */
  padding-left: 1rem; /* Indent padding */
  border-left: 4px solid #ddd; /* Left border for blockquote */
  color: #666; /* Lighter text color */
  font-style: italic; /* Italic text */
}

/* Media Queries for Responsive Font Sizes */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  h5 {
    font-size: 1.125rem;
  }
  h6 {
    font-size: 1rem;
  }
}
