.ukata-square-input {
  width: 60px;
}

.ukata-80-input {
  width: 80px;
}

.ukata-100-input {
  width: 100px;
}

.fg-speech-assessment-dark-green {
  color: #0b5b00;
}

.fg-speech-assessment-green {
  color: #00a65a;
}

.fg-speech-assessment-yellow {
  color: #ffd700;
}

.fg-speech-assessment-orange {
  color: #d27200;
}

.fg-speech-assessment-red {
  color: #af3128;
}

.fg-speech-assessment-dark-red {
  color: #570000;
}
