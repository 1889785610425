:root {
  --bs-blue: #3b7ddd;
  --bs-indigo: #0a0a0a;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #fcb92c;
  --bs-green: #1cbb8c;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #3b7ddd;
  --bs-secondary: #6c757d;
  --bs-success: #1cbb8c;
  --bs-info: #17a2b8;
  --bs-warning: #fcb92c;
  --bs-danger: #dc3545;
  --bs-light: #f5f7fb;
  --bs-dark: #212529;
  --bs-primary-rgb: 59, 125, 221;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 28, 187, 140;
  --bs-info-rgb: 23, 162, 184;
  --bs-warning-rgb: 252, 185, 44;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 245, 247, 251;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 73, 80, 87;
  --bs-body-bg-rgb: 245, 247, 251;
  --bs-font-sans-serif: "Noto Sans", "Helvetica Neue";
  --bs-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #495057;
  --bs-body-bg: #f5f7fb;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.2rem;
  --bs-border-radius-sm: 0.1rem;
  --bs-border-radius-lg: 0.3rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #3b7ddd;
  --bs-link-hover-color: #2f64b1;
  --bs-code-color: #e83e8c;
  --bs-highlight-bg: #fef1d5;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  height: auto;
  transition: width 0.35s ease;
  width: 0;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
  content: "";
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: #495057;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.2rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.2rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #3b7ddd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  background-clip: padding-box;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  color: var(--bs-dropdown-color);
  display: none;
  font-size: var(--bs-dropdown-font-size);
  list-style: none;
  margin: 0;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  position: absolute;
  text-align: left;
  z-index: 1000;
}

.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1440px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  bottom: 100%;
  margin-bottom: var(--bs-dropdown-spacer);
  margin-top: 0;
  top: auto;
}

.dropup .dropdown-toggle:after {
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  left: 100%;
  margin-left: var(--bs-dropdown-spacer);
  margin-top: 0;
  right: auto;
  top: 0;
}

.dropend .dropdown-toggle:after {
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  border-right: 0;
  border-top: 0.3em solid transparent;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  left: auto;
  margin-right: var(--bs-dropdown-spacer);
  margin-top: 0;
  right: 100%;
  top: 0;
}

.dropstart .dropdown-toggle:after {
  content: "";
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropstart .dropdown-toggle:before {
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-top: 0.3em solid transparent;
  content: "";
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: var(--bs-dropdown-link-color);
  display: block;
  font-weight: 400;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--bs-dropdown-link-hover-bg);
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--bs-dropdown-link-active-bg);
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  background-color: transparent;
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: var(--bs-dropdown-header-color);
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0;
  padding: var(--bs-dropdown-header-padding-y)
    var(--bs-dropdown-header-padding-x);
  white-space: nowrap;
}

.dropdown-item-text {
  color: var(--bs-dropdown-link-color);
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: hsla(0, 0%, 100%, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #3b7ddd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  flex: 1 1 auto;
  position: relative;
}

.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.2rem;
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: 0.6375rem;
  padding-right: 0.6375rem;
}

.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  color: var(--bs-nav-link-color);
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:focus,
.nav-link:hover {
  color: var(--bs-nav-link-hover-color);
  text-decoration: none;
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  cursor: default;
  pointer-events: none;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.2rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #f5f7fb;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #f5f7fb;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: var(--bs-nav-tabs-link-hover-border-color);
  isolation: isolate;
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--bs-nav-link-disabled-color);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  color: var(--bs-nav-tabs-link-active-color);
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.2rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #3b7ddd;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link:disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--bs-nav-link-disabled-color);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--bs-nav-pills-link-active-bg);
  color: var(--bs-nav-pills-link-active-color);
}

.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 1.375rem;
  --bs-navbar-padding-y: 0.875rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.875rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.15rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 0.925rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.2rem;
  --bs-navbar-toggler-focus-width: 0.2rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  position: relative;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl {
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
}

.navbar-brand {
  color: var(--bs-navbar-brand-color);
  font-size: var(--bs-navbar-brand-font-size);
  margin-right: var(--bs-navbar-brand-margin-end);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  padding-top: var(--bs-navbar-brand-padding-y);
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  color: var(--bs-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.navbar-text a,
.navbar-text a:focus,
.navbar-text a:hover {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}

.navbar-toggler {
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  color: var(--bs-navbar-color);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}

.navbar-nav-scroll {
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}

@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: var(--bs-navbar-nav-link-padding-x);
  padding-right: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  background-color: transparent !important;
  border: 0 !important;
  flex-grow: 1;
  height: auto !important;
  position: static;
  transform: none !important;
  transition: none;
  visibility: visible !important;
  width: auto !important;
  z-index: auto;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  overflow-y: visible;
  padding: 0;
}

.navbar-dark {
  --bs-navbar-color: hsla(0, 0%, 100%, 0.55);
  --bs-navbar-hover-color: hsla(0, 0%, 100%, 0.75);
  --bs-navbar-disabled-color: hsla(0, 0%, 100%, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: hsla(0, 0%, 100%, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}

@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-bottom-width: var(--bs-list-group-border-width);
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  background-color: #d8e5f8;
  color: #234b85;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  background-color: #c2cedf;
  color: #234b85;
}

.list-group-item-primary.list-group-item-action.active {
  background-color: #234b85;
  border-color: #234b85;
  color: #fff;
}

.list-group-item-secondary {
  background-color: #e2e3e5;
  color: #41464b;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  background-color: #cbccce;
  color: #41464b;
}

.list-group-item-secondary.list-group-item-action.active {
  background-color: #41464b;
  border-color: #41464b;
  color: #fff;
}

.list-group-item-success {
  background-color: #d2f1e8;
  color: #117054;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  background-color: #bdd9d1;
  color: #117054;
}

.list-group-item-success.list-group-item-action.active {
  background-color: #117054;
  border-color: #117054;
  color: #fff;
}

.list-group-item-info {
  background-color: #d1ecf1;
  color: #0e616e;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  background-color: #bcd4d9;
  color: #0e616e;
}

.list-group-item-info.list-group-item-action.active {
  background-color: #0e616e;
  border-color: #0e616e;
  color: #fff;
}

.list-group-item-warning {
  background-color: #fef1d5;
  color: #976f1a;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  background-color: #e5d9c0;
  color: #976f1a;
}

.list-group-item-warning.list-group-item-action.active {
  background-color: #976f1a;
  border-color: #976f1a;
  color: #fff;
}

.list-group-item-danger {
  background-color: #f8d7da;
  color: #842029;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  background-color: #dfc2c4;
  color: #842029;
}

.list-group-item-danger.list-group-item-action.active {
  background-color: #842029;
  border-color: #842029;
  color: #fff;
}

.list-group-item-light {
  background-color: #fdfdfe;
  color: #626364;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  background-color: #e4e4e5;
  color: #626364;
}

.list-group-item-light.list-group-item-action.active {
  background-color: #626364;
  border-color: #626364;
  color: #fff;
}

.list-group-item-dark {
  background-color: #d3d3d4;
  color: #141619;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  background-color: #bebebf;
  color: #141619;
}

.list-group-item-dark.list-group-item-action.active {
  background-color: #141619;
  border-color: #141619;
  color: #fff;
}

.btn-close {
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.2rem;
  box-sizing: content-box;
  color: #000;
  height: 1em;
  opacity: 0.5;
  padding: 0.25em;
  width: 1em;
}

.btn-close:hover {
  color: #000;
  opacity: 0.75;
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
  opacity: 1;
  outline: 0;
}

.btn-close.disabled,
.btn-close:disabled {
  opacity: 0.25;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  background-color: RGBA(59, 125, 221, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}

.text-bg-secondary {
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}

.text-bg-success {
  background-color: RGBA(28, 187, 140, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}

.text-bg-info {
  background-color: RGBA(23, 162, 184, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}

.text-bg-warning {
  background-color: RGBA(252, 185, 44, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}

.text-bg-danger {
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}

.text-bg-light {
  background-color: RGBA(245, 247, 251, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}

.text-bg-dark {
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}

.link-primary {
  color: #3b7ddd !important;
}

.link-primary:focus,
.link-primary:hover {
  color: #2f64b1 !important;
}

.link-secondary {
  color: #6c757d !important;
}

.link-secondary:focus,
.link-secondary:hover {
  color: #565e64 !important;
}

.link-success {
  color: #1cbb8c !important;
}

.link-success:focus,
.link-success:hover {
  color: #49c9a3 !important;
}

.link-info {
  color: #17a2b8 !important;
}

.link-info:focus,
.link-info:hover {
  color: #128293 !important;
}

.link-warning {
  color: #fcb92c !important;
}

.link-warning:focus,
.link-warning:hover {
  color: #fdc756 !important;
}

.link-danger {
  color: #dc3545 !important;
}

.link-danger:focus,
.link-danger:hover {
  color: #b02a37 !important;
}

.link-light {
  color: #f5f7fb !important;
}

.link-light:focus,
.link-light:hover {
  color: #f7f9fc !important;
}

.link-dark {
  color: #212529 !important;
}

.link-dark:focus,
.link-dark:hover {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio:before {
  content: "";
  display: block;
  padding-top: var(--bs-aspect-ratio);
}

.ratio > * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  top: 0;
}

.fixed-bottom,
.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
}

.sticky-top {
  top: 0;
}

.sticky-bottom,
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1020;
}

.sticky-bottom {
  bottom: 0;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    top: 0;
  }

  .sticky-sm-bottom,
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    top: 0;
  }

  .sticky-md-bottom,
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
  }

  .sticky-md-bottom {
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    top: 0;
  }

  .sticky-lg-bottom,
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    top: 0;
  }

  .sticky-xl-bottom,
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    bottom: 0;
  }
}

@media (min-width: 1440px) {
  .sticky-xxl-top {
    top: 0;
  }

  .sticky-xxl-bottom,
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    bottom: 0;
  }
}

.hstack {
  align-items: center;
  flex-direction: row;
}

.hstack,
.vstack {
  align-self: stretch;
  display: flex;
}

.vstack {
  flex: 1 1 auto;
  flex-direction: column;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.stretched-link:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  align-self: stretch;
  background-color: currentcolor;
  display: inline-block;
  min-height: 1em;
  opacity: 0.25;
  width: 1px;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05) !important;
}

.shadow-sm {
  box-shadow: 0 0.05rem 0.2rem rgba(0, 0, 0, 0.05) !important;
}

.shadow-lg {
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.05) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-success-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
  box-sizing: border-box;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-6 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.mx-7 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.my-1 {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}

.my-2 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}

.my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}

.my-5 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
}

.my-6 {
  margin-bottom: 4.5rem !important;
  margin-top: 4.5rem !important;
}

.my-7 {
  margin-bottom: 6rem !important;
  margin-top: 6rem !important;
}

.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4.5rem !important;
}

.mt-7 {
  margin-top: 6rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 4.5rem !important;
}

.me-7 {
  margin-right: 6rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4.5rem !important;
}

.mb-7 {
  margin-bottom: 6rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 4.5rem !important;
}

.ms-7 {
  margin-left: 6rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.mx-n1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.mx-n2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.mx-n6 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}

.mx-n7 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.my-n1 {
  margin-bottom: -0.25rem !important;
  margin-top: -0.25rem !important;
}

.my-n2 {
  margin-bottom: -0.5rem !important;
  margin-top: -0.5rem !important;
}

.my-n3 {
  margin-bottom: -1rem !important;
  margin-top: -1rem !important;
}

.my-n4 {
  margin-bottom: -1.5rem !important;
  margin-top: -1.5rem !important;
}

.my-n5 {
  margin-bottom: -3rem !important;
  margin-top: -3rem !important;
}

.my-n6 {
  margin-bottom: -4.5rem !important;
  margin-top: -4.5rem !important;
}

.my-n7 {
  margin-bottom: -6rem !important;
  margin-top: -6rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -4.5rem !important;
}

.mt-n7 {
  margin-top: -6rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -4.5rem !important;
}

.me-n7 {
  margin-right: -6rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -4.5rem !important;
}

.mb-n7 {
  margin-bottom: -6rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -4.5rem !important;
}

.ms-n7 {
  margin-left: -6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-6 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.px-7 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.py-1 {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}

.py-2 {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}

.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}

.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}

.py-6 {
  padding-bottom: 4.5rem !important;
  padding-top: 4.5rem !important;
}

.py-7 {
  padding-bottom: 6rem !important;
  padding-top: 6rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4.5rem !important;
}

.pt-7 {
  padding-top: 6rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 4.5rem !important;
}

.pe-7 {
  padding-right: 6rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4.5rem !important;
}

.pb-7 {
  padding-bottom: 6rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 4.5rem !important;
}

.ps-7 {
  padding-left: 6rem !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-6 {
    margin: 4.5rem !important;
  }

  .m-sm-7 {
    margin: 6rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-sm-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .my-sm-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }

  .my-sm-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }

  .my-sm-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }

  .my-sm-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }

  .my-sm-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }

  .my-sm-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }

  .my-sm-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }

  .my-sm-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-6 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 6rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-6 {
    margin-right: 4.5rem !important;
  }

  .me-sm-7 {
    margin-right: 6rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-6 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 6rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .m-sm-n6 {
    margin: -4.5rem !important;
  }

  .m-sm-n7 {
    margin: -6rem !important;
  }

  .mx-sm-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-sm-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-sm-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }

  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }

  .my-sm-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }

  .my-sm-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .mt-sm-n6 {
    margin-top: -4.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -6rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .me-sm-n6 {
    margin-right: -4.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -6rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -4.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -6rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .ms-sm-n6 {
    margin-left: -4.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -6rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .p-sm-6 {
    padding: 4.5rem !important;
  }

  .p-sm-7 {
    padding: 6rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-sm-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-sm-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-sm-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .py-sm-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }

  .py-sm-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }

  .py-sm-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }

  .py-sm-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }

  .py-sm-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }

  .py-sm-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }

  .py-sm-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-6 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 6rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pe-sm-6 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 6rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 6rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .ps-sm-6 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 6rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .gap-sm-6 {
    gap: 4.5rem !important;
  }

  .gap-sm-7 {
    gap: 6rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-6 {
    margin: 4.5rem !important;
  }

  .m-md-7 {
    margin: 6rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-md-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .my-md-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }

  .my-md-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }

  .my-md-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }

  .my-md-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }

  .my-md-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }

  .my-md-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }

  .my-md-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }

  .my-md-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-6 {
    margin-top: 4.5rem !important;
  }

  .mt-md-7 {
    margin-top: 6rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-6 {
    margin-right: 4.5rem !important;
  }

  .me-md-7 {
    margin-right: 6rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-6 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 6rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-6 {
    margin-left: 4.5rem !important;
  }

  .ms-md-7 {
    margin-left: 6rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .m-md-n6 {
    margin: -4.5rem !important;
  }

  .m-md-n7 {
    margin: -6rem !important;
  }

  .mx-md-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-md-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-md-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-md-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }

  .my-md-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }

  .my-md-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }

  .my-md-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }

  .my-md-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }

  .my-md-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }

  .my-md-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .mt-md-n6 {
    margin-top: -4.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -6rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .me-md-n6 {
    margin-right: -4.5rem !important;
  }

  .me-md-n7 {
    margin-right: -6rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -4.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -6rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .ms-md-n6 {
    margin-left: -4.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -6rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .p-md-6 {
    padding: 4.5rem !important;
  }

  .p-md-7 {
    padding: 6rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-md-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-md-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-md-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .py-md-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }

  .py-md-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }

  .py-md-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }

  .py-md-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }

  .py-md-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }

  .py-md-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }

  .py-md-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-6 {
    padding-top: 4.5rem !important;
  }

  .pt-md-7 {
    padding-top: 6rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pe-md-6 {
    padding-right: 4.5rem !important;
  }

  .pe-md-7 {
    padding-right: 6rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-6 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 6rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .ps-md-6 {
    padding-left: 4.5rem !important;
  }

  .ps-md-7 {
    padding-left: 6rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .gap-md-6 {
    gap: 4.5rem !important;
  }

  .gap-md-7 {
    gap: 6rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-6 {
    margin: 4.5rem !important;
  }

  .m-lg-7 {
    margin: 6rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-lg-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .my-lg-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }

  .my-lg-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }

  .my-lg-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }

  .my-lg-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }

  .my-lg-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }

  .my-lg-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }

  .my-lg-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }

  .my-lg-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-6 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 6rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-6 {
    margin-right: 4.5rem !important;
  }

  .me-lg-7 {
    margin-right: 6rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-6 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 6rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .m-lg-n6 {
    margin: -4.5rem !important;
  }

  .m-lg-n7 {
    margin: -6rem !important;
  }

  .mx-lg-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-lg-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-lg-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }

  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }

  .my-lg-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }

  .my-lg-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .mt-lg-n6 {
    margin-top: -4.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -6rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .me-lg-n6 {
    margin-right: -4.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -6rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -4.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -6rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .ms-lg-n6 {
    margin-left: -4.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -6rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .p-lg-6 {
    padding: 4.5rem !important;
  }

  .p-lg-7 {
    padding: 6rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .px-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-lg-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-lg-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-lg-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .py-lg-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }

  .py-lg-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }

  .py-lg-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }

  .py-lg-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }

  .py-lg-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }

  .py-lg-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }

  .py-lg-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-6 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 6rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pe-lg-6 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 6rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 6rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .ps-lg-6 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 6rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .gap-lg-6 {
    gap: 4.5rem !important;
  }

  .gap-lg-7 {
    gap: 6rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-6 {
    margin: 4.5rem !important;
  }

  .m-xl-7 {
    margin: 6rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xl-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .my-xl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }

  .my-xl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }

  .my-xl-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }

  .my-xl-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }

  .my-xl-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }

  .my-xl-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }

  .my-xl-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }

  .my-xl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-6 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 6rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-6 {
    margin-right: 4.5rem !important;
  }

  .me-xl-7 {
    margin-right: 6rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-6 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 6rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .m-xl-n6 {
    margin: -4.5rem !important;
  }

  .m-xl-n7 {
    margin: -6rem !important;
  }

  .mx-xl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xl-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-xl-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }

  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }

  .my-xl-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }

  .my-xl-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .mt-xl-n6 {
    margin-top: -4.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -6rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .me-xl-n6 {
    margin-right: -4.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -6rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -6rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .ms-xl-n6 {
    margin-left: -4.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -6rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .p-xl-6 {
    padding: 4.5rem !important;
  }

  .p-xl-7 {
    padding: 6rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .px-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xl-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-xl-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-xl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .py-xl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }

  .py-xl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }

  .py-xl-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }

  .py-xl-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }

  .py-xl-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }

  .py-xl-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }

  .py-xl-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-6 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 6rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pe-xl-6 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 6rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 6rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .ps-xl-6 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 6rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .gap-xl-6 {
    gap: 4.5rem !important;
  }

  .gap-xl-7 {
    gap: 6rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-6 {
    margin: 4.5rem !important;
  }

  .m-xxl-7 {
    margin: 6rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xxl-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }

  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }

  .my-xxl-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }

  .my-xxl-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }

  .my-xxl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-6 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 6rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-6 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 6rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-6 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 6rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .m-xxl-n6 {
    margin: -4.5rem !important;
  }

  .m-xxl-n7 {
    margin: -6rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xxl-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-xxl-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }

  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }

  .my-xxl-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }

  .my-xxl-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -4.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -6rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .me-xxl-n6 {
    margin-right: -4.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -6rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -6rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -4.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -6rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .p-xxl-6 {
    padding: 4.5rem !important;
  }

  .p-xxl-7 {
    padding: 6rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xxl-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-xxl-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-xxl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }

  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }

  .py-xxl-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }

  .py-xxl-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pt-xxl-6 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 6rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pe-xxl-6 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 6rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .ps-xxl-6 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 6rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .gap-xxl-6 {
    gap: 4.5rem !important;
  }

  .gap-xxl-7 {
    gap: 6rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

.content {
  direction: ltr;
  max-width: 100vw;
  box-sizing: border-box;
  padding: 1.5rem 1.5rem 0.75rem;
  width: 100vw;
}

@media (min-width: 768px) {
  .content {
    width: auto;
  }
}

@media (min-width: 992px) {
  .content {
    padding: 3rem 3rem 1.5rem;
  }
}

.navbar-nav .dropdown-menu {
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
}

.dropdown .dropdown-menu.show {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: dropdownAnimation;
  animation-name: dropdownAnimation;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}

@-webkit-keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translate(0);
  }
}

.dropdown-toggle:after {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
}

.dropdown-item {
  transition:
    background 0.1s ease-in-out,
    color 0.1s ease-in-out;
}

.dropdown-menu {
  top: auto;
}

.dropdown-menu-lg {
  min-width: 20rem;
}

.dropdown .list-group .list-group-item {
  border-width: 0 0 1px;
  margin-bottom: 0;
}

.dropdown .list-group .list-group-item:first-child,
.dropdown .list-group .list-group-item:last-child {
  border-radius: 0;
}

.dropdown .list-group .list-group-item:hover {
  background: #f8f9fa;
}

.dropdown-menu-header {
  border-bottom: 1px solid #dee2e6;
  font-weight: 600;
  padding: 0.75rem;
  text-align: center;
}

.dropdown-menu-footer {
  display: block;
  font-size: 0.75rem;
  padding: 0.5rem;
  text-align: center;
}

.feather {
  stroke-width: 2;
  height: 18px;
  width: 18px;
}

.feather-sm {
  height: 14px;
  width: 14px;
}

.feather-lg {
  height: 36px;
  width: 36px;
}

footer.footer {
  background: #fff;
  direction: ltr;
  padding: 1rem 0.875rem;
}

footer.footer ul {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  footer.footer {
    width: 100vw;
    box-sizing: border-box;
  }
}

.hamburger,
.hamburger:after,
.hamburger:before {
  background: #495057;
  border-radius: 1px;
  content: "";
  cursor: pointer;
  display: block;
  height: 3px;
  transition:
    background 0.1s ease-in-out,
    color 0.1s ease-in-out;
  width: 24px;
}

.hamburger {
  position: relative;
}

.hamburger:before {
  position: absolute;
  top: -7.5px;
  width: 24px;
}

.hamburger:after {
  bottom: -7.5px;
  position: absolute;
  width: 16px;
}

.sidebar-toggle:hover .hamburger,
.sidebar-toggle:hover .hamburger:after,
.sidebar-toggle:hover .hamburger:before {
  background: #3b7ddd;
}

.hamburger-right,
.hamburger-right:after,
.hamburger-right:before {
  right: 0;
}

a.list-group-item {
  text-decoration: none;
}

.main {
  background: #f5f7fb;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 0;
  overflow: hidden;
  transition:
    margin-left 0.35s ease-in-out,
    left 0.35s ease-in-out,
    margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out;
  width: 100%;
}

.navbar {
  border-bottom: 0;
  box-shadow: 0 0 2rem 0 rgba(33, 37, 41, 0.1);
}

@media (max-width: 768px) {
  .navbar {
    width: 100vw;
    box-sizing: border-box;
  }
}

.navbar .avatar {
  margin-bottom: -15px;
  margin-top: -15px;
}

.navbar-nav {
  direction: ltr;
}

.navbar-align {
  margin-left: auto;
}

.navbar-bg {
  background: #fff;
}

.navbar-brand {
  color: #f8f9fa;
  display: block;
  font-size: 1.15rem;
  font-weight: 400;
  padding: 0.875rem 0;
}

.navbar-brand .feather,
.navbar-brand svg {
  color: #3b7ddd;
  height: 24px;
  margin-left: -0.15rem;
  margin-right: 0.375rem;
  margin-top: -0.375rem;
  width: 24px;
}

.nav-flag,
.nav-icon {
  color: #6c757d;
  display: block;
  font-size: 1.5rem;
  line-height: 1.4;
  padding: 0.1rem 0.8rem;
  transition:
    background 0.1s ease-in-out,
    color 0.1s ease-in-out;
}

.nav-flag:after,
.nav-icon:after {
  display: none !important;
}

.nav-flag.active,
.nav-flag:hover,
.nav-icon.active,
.nav-icon:hover {
  color: #3b7ddd;
}

.nav-flag .feather,
.nav-flag svg,
.nav-icon .feather,
.nav-icon svg {
  height: 20px;
  width: 20px;
}

.nav-item .indicator {
  background: #3b7ddd;
  border-radius: 50%;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  color: #fff;
  display: block;
  font-size: 0.675rem;
  height: 18px;
  padding: 1px;
  position: absolute;
  right: -8px;
  text-align: center;
  top: 0;
  transition: top 0.1s ease-out;
  width: 18px;
}

.nav-item:hover .indicator {
  top: -4px;
}

.nav-item a:focus {
  outline: 0;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .navbar .avatar {
    max-height: 47px;
  }
}

@media (max-width: 575.98px) {
  .navbar {
    padding: 0.75rem;
  }

  .nav-icon {
    padding: 0.1rem 0.75rem;
  }

  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: inherit;
  }

  .navbar-expand .navbar-nav .dropdown-menu-lg {
    min-width: 100%;
  }

  .nav-item .nav-link:after {
    display: none;
  }
}

.nav-flag img {
  border-radius: 50%;
  height: 20px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 20px;
}

.navbar input {
  direction: ltr;
}

#root,
body,
html {
  height: 100%;
}

body {
  opacity: 1 !important;
  overflow-y: scroll;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  html {
    overflow-x: hidden;
  }
}

.sidebar {
  direction: ltr;
  max-width: 260px;
  min-width: 260px;
}

.sidebar,
.sidebar-content {
  background: #222e3c;
  transition:
    margin-left 0.35s ease-in-out,
    left 0.35s ease-in-out,
    margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.sidebar-nav {
  flex-grow: 1;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.sidebar-link,
a.sidebar-link {
  background: #222e3c;
  border-left: 3px solid transparent;
  color: rgba(233, 236, 239, 0.5);
  cursor: pointer;
  display: block;
  font-weight: 400;
  padding: 0.525rem 1.425rem;
  position: relative;
  text-decoration: none;
  transition: background 0.1s ease-in-out;
}

.sidebar-link i,
.sidebar-link svg,
a.sidebar-link i,
a.sidebar-link svg {
  color: rgba(233, 236, 239, 0.5);
  margin-right: 0.75rem;
}

.sidebar-link:focus {
  outline: 0;
}

.sidebar-link:hover {
  background: #222e3c;
  border-left-color: transparent;
}

.sidebar-link:hover,
.sidebar-link:hover i,
.sidebar-link:hover svg {
  color: rgba(233, 236, 239, 0.75);
}

.sidebar-item {
  margin: 0;
}

.sidebar-item.active .sidebar-link:hover,
.sidebar-item.active > .sidebar-link {
  background: linear-gradient(
    90deg,
    rgba(59, 125, 221, 0.1),
    rgba(59, 125, 221, 0.088) 50%,
    transparent
  );
  border-left-color: #3b7ddd;
  color: #e9ecef;
}

.sidebar-item.active .sidebar-link:hover i,
.sidebar-item.active .sidebar-link:hover svg,
.sidebar-item.active > .sidebar-link i,
.sidebar-item.active > .sidebar-link svg {
  color: #e9ecef;
}

.sidebar-brand {
  color: #f8f9fa;
  display: block;
  font-size: 1.15rem;
  font-weight: 600;
  padding: 1.15rem 1.5rem;
}

.sidebar-brand:hover {
  color: #f8f9fa;
  text-decoration: none;
}

.sidebar-brand:focus {
  outline: 0;
}

.sidebar-toggle {
  cursor: pointer;
  display: flex;
  height: 26px;
  width: 26px;
}

.sidebar.collapsed {
  margin-left: -260px;
}

@media (min-width: 1px) and (max-width: 991.98px) {
  .sidebar {
    margin-left: -260px;
  }

  .sidebar.collapsed {
    margin-left: 0;
  }
}

.sidebar-toggle {
  margin-right: 1rem;
}

.sidebar-header {
  background: transparent;
  color: #ced4da;
  font-size: 0.75rem;
  padding: 1rem 1.5rem 0.375rem;
}

.sidebar-badge {
  position: absolute;
  right: 15px;
  top: 14px;
  z-index: 1;
}

.sidebar-cta-content {
  background: #2b3947;
  border-radius: 0.3rem;
  color: #e9ecef;
  margin: 1.75rem;
  padding: 1.5rem;
}

.min-vw-50 {
  min-width: 50vw !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.vw-50 {
  width: 50vw !important;
}

.vh-50 {
  height: 50vh !important;
}

.stat {
  align-items: center;
  background: #d3e2f7;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.stat svg {
  stroke-width: 1.5;
  color: #3b7ddd !important;
  height: 18px;
  width: 18px;
}

.text-sm {
  font-size: 0.75rem;
}

.text-lg {
  font-size: 0.925rem;
}

b,
strong {
  font-weight: 600;
}

pre.snippet {
  word-wrap: break-word;
  text-align: justify;
  white-space: pre-wrap;
}

a {
  cursor: pointer;
}

.wrapper {
  align-items: stretch;
  background: #222e3c;
  display: flex;
  width: 100%;
}

.cursor-grab {
  cursor: move;
  cursor: grab;
}

.cursor-pointer {
  cursor: pointer;
}

svg {
  touch-action: none;
}

.jvm-zoomin,
.jvm-zoomout,
image,
text {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
