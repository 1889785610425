// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$kata-ui-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$kata-ui-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$kata-ui-warn: mat.m2-define-palette(mat.$m2-red-palette);

$kata-ui-tree: mat.m2-define-palette(mat.$m2-green-palette);
$kata-ui-gold: mat.m2-define-palette(mat.$m2-yellow-palette);

$kata-ui-typography: mat.m2-define-typography-config(
  // Specify "Comic Sans" as the default font family for all levels.
  $font-family: "Noto Sans"
);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$kata-ui-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $kata-ui-primary,
      accent: $kata-ui-accent,
      warn: $kata-ui-warn,
      tree: $kata-ui-tree,
      gold: $kata-ui-gold,
    ),
    typography: $kata-ui-typography,
  )
);

$mini-form: mat.m2-define-light-theme(
  (
    density: -2,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($kata-ui-theme);

@import "assets/styles/app.css";
@import "quill/dist/quill.snow.css"; //do not remove

//@import "primeng/resources/themes/lara-light-blue/theme.css";
//@import "primeng/resources/primeng.css";
/* You can add global styles to this file, and also import other style files */

.mat-mdc-tab-body-wrapper {
  .mat-mdc-tab-body.mat-mdc-tab-body-active {
    overflow-y: inherit !important;
  }

  .mat-mdc-tab-body-content {
    overflow-y: inherit !important;
    overflow-x: hidden;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Noto Sans", "Helvetica Neue", sans-serif;
}

#blog-content,
#blog-edit {
  table {
    border-collapse: collapse;
    width: 100%;

    td,
    th {
      border: 1px solid #484848;
      text-align: left;
      padding: 8px;
    }
  }
}

/* Default Variables */

$font-family: "Noto Sans", Arial, sans-serif;
$base-font-size: 14px;
$mobile-font-size: 16px;
$line-height: 1.5;
$heading-margin: 1rem 0;
$paragraph-margin: 1rem 0;
$list-margin: 1rem 0;
$list-item-margin: 0.5rem 0;

/* Desktop Styles */
/* Info notification */

.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background-color: inherit !important;
    color: inherit;
  }

  &.info-notification-overlay {
    background-color: #1b5e94;
    color: #fff;
  }

  /* Warning notification */
  &.warn-notification-overlay {
    background-color: #ffc107;
    color: #000;
  }

  /* Error notification */
  &.error-notification-overlay {
    background-color: #af3128;
    color: #fff;
  }

  /* Success notification */
  &.success-notification-overlay {
    background-color: #28732b;
    color: #fff;
  }
}

/* Common styles for all notifications */
.notification-overlay {
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

body {
  font-family: $font-family;
  font-size: $base-font-size;
  line-height: $line-height;
  color: #333;
  background-color: #fff;
}

table {
  font-family: $font-family !important;
}

button {
  font-family: $font-family;
}

h1,
h2,
h3 {
  font-weight: bold;
  margin: $heading-margin;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  margin: $paragraph-margin;
}

ul,
ol {
  margin: $list-margin;
}

li {
  margin: $list-item-margin;
}

/* Mobile Styles */

@media only screen and (max-width: 767px) {
  body {
    font-size: $mobile-font-size;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}

img,
audio,
video {
  max-width: 100%;
}

/* generated by https://loading.io/ */
#ukata-splash-screen {
  z-index: 999999;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  img {
    width: 128px;
  }

  @keyframes ldio-sdvzqjwldb-1 {
    0% {
      top: 36px;
      height: 128px;
    }
    50% {
      top: 60px;
      height: 80px;
    }
    100% {
      top: 60px;
      height: 80px;
    }
  }

  @keyframes ldio-sdvzqjwldb-2 {
    0% {
      top: 41.99999999999999px;
      height: 116.00000000000001px;
    }
    50% {
      top: 60px;
      height: 80px;
    }
    100% {
      top: 60px;
      height: 80px;
    }
  }

  @keyframes ldio-sdvzqjwldb-3 {
    0% {
      top: 48px;
      height: 104px;
    }
    50% {
      top: 60px;
      height: 80px;
    }
    100% {
      top: 60px;
      height: 80px;
    }
  }

  .ldio-sdvzqjwldb div {
    position: absolute;
    width: 30px;
  }

  .ldio-sdvzqjwldb div:nth-child(1) {
    left: 35px;
    background: #e15b64;
    animation: ldio-sdvzqjwldb-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.2s;
  }

  .ldio-sdvzqjwldb div:nth-child(2) {
    left: 85px;
    background: #f8b26a;
    animation: ldio-sdvzqjwldb-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.1s;
  }

  .ldio-sdvzqjwldb div:nth-child(3) {
    left: 135px;
    background: #abbd81;
    animation: ldio-sdvzqjwldb-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  .loadingio-spinner-pulse-9yczsv63mqg {
    width: 200px;
    height: 100px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
  }

  .ldio-sdvzqjwldb {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
}

.ukata-ul-no-padding {
  padding: 0;
  list-style-type: none;
}

.ukata-simple-box {
  padding: 15px;
  box-sizing: border-box;
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.ukata-simple-box-no-padding {
  padding: 5px;
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);

  li {
    list-style-type: none;
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.ck.ck-content.ck-editor__editable {
  border: 1px solid #ccc;
}

ul,
ol {
  padding: 0;
}

@import "assets/styles/custom";
@import "assets/styles/bootstrap-grid.css";
@import "assets/styles/custom-material";
@import "prismjs/plugins/toolbar/prism-toolbar.min.css";
@import "prismjs/plugins/line-numbers/prism-line-numbers.min.css";
@import "prismjs/plugins/line-highlight/prism-line-highlight.min.css";
@import "../src/assets/styles/prism-darcula.min.css";
@import "prismjs/plugins/toolbar/prism-toolbar.css";
