code[class*="language-"],
pre[class*="language-"] {
  color: #a9b7c6;
  font-family: Consolas, Monaco, "Andale Mono", monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
code[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
pre[class*="language-"]::-moz-selection {
  color: inherit;
  background: rgba(33, 66, 131, 0.85);
}
code[class*="language-"] ::selection,
code[class*="language-"]::selection,
pre[class*="language-"] ::selection,
pre[class*="language-"]::selection {
  color: inherit;
  background: rgba(33, 66, 131, 0.85);
}
pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #2b2b2b;
}
:not(pre) > code[class*="language-"] {
  padding: 0.1em;
  border-radius: 0.3em;
}
.token.cdata,
.token.comment,
.token.prolog {
  color: grey;
}
.token.atrule,
.token.boolean,
.token.delimiter,
.token.important,
.token.keyword,
.token.selector {
  color: #cc7832;
}
.token.attr-name,
.token.operator,
.token.punctuation {
  color: #a9b7c6;
}
.token.builtin,
.token.doctype,
.token.tag,
.token.tag .punctuation {
  color: #e8bf6a;
}
.token.entity,
.token.number,
.token.symbol {
  color: #6897bb;
}
.token.constant,
.token.property,
.token.variable {
  color: #9876aa;
}
.token.char,
.token.string {
  color: #6a8759;
}
.token.attr-value,
.token.attr-value .punctuation {
  color: #a5c261;
}
.token.attr-value .punctuation:first-child {
  color: #a9b7c6;
}
.token.url {
  color: #287bde;
  text-decoration: underline;
}
.token.function {
  color: #ffc66d;
}
.token.regex {
  background: #364135;
}
.token.bold {
  font-weight: 700;
}
.token.italic {
  font-style: italic;
}
.token.inserted {
  background: #294436;
}
.token.deleted {
  background: #484a4a;
}
code.language-css .token.property,
code.language-css .token.property + .token.punctuation {
  color: #a9b7c6;
}
code.language-css .token.id {
  color: #ffc66d;
}
code.language-css .token.selector > .token.attribute,
code.language-css .token.selector > .token.class,
code.language-css .token.selector > .token.pseudo-class,
code.language-css .token.selector > .token.pseudo-element {
  color: #ffc66d;
}
