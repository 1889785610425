nz-form-item,
.ant-form-item {
  margin-bottom: 16px;
}
nz-form-label {
  font-weight: bold;
  font-size: 14px;
}
nz-select-item,
input[nz-input] {
  font-size: 14px;
}
nz-select.ant-select {
  width: 100%;
  min-width: 80px;
}

nz-form-label {
  font-weight: bold;
  font-size: 14px;
}
